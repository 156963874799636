import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import postMessageToLitten from '../utils/postMessageToLitten';
import config from '../services/config';
import { saveLittenSlide, saveSlide } from '../Store/actions/slide';
import { setCurrentUser, setHasConversations } from '../Store/Authentication/Action';
import { accountDataAdapter } from '../services/initialize';
import { RootState } from '../Interfaces/Default';
import { initializeLDClient } from '../services/launchDarklyService';

import { LittenMessageEvent } from './useLittenMessages';
import useSearchParams from './useSearchParams';

const useIFrameMessages = () => {
  const dispatch = useDispatch();
  const searchParams = useSearchParams();
  const isInitialized = useRef<boolean>();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );

  const [littenChatPreviewId, setLittenChatPreviewId] = useState<string | null>(
    null,
  );

  const manageIframeMessages = (event: LittenMessageEvent) => {
    if (
      event.origin === config.urls.LITTEN
      && event.data.id
      && event.data.id === 'litten'
      && event.data.action
    ) {
      switch (event.data.action) {
        case 'userData':
          if (!isAuthenticated) {
            const data = {
              account: event.data.values?.account,
              user: event.data.values?.user,
              plan: event.data.values?.plan,
              companyHasConversations: event.data.values?.companyHasConversations,
            };
            const accountData = accountDataAdapter(data);
            if (accountData) {
              dispatch(setCurrentUser(accountData));
            }
            if (data.user && data.account) {
              initializeLDClient(data.user, data.account);
            }
          }

          break;
        case 'updateHasConversations':
          if (event.data.values?.companyHasConversations !== undefined) {
            dispatch(setHasConversations(event.data.values.companyHasConversations));
          }
          break;
        case 'slideToggle':
          dispatch(
            saveSlide({
              open: event.data.values.newValue,
            }),
          );
          break;
        case 'chatPreviewToggle': {
          const { chatId } = event.data.values;
          setLittenChatPreviewId(chatId);
          dispatch(saveLittenSlide({ isLittenPanelOpen: chatId !== null }));
          if (!chatId) {
            dispatch(
              saveSlide({
                open: false,
              }),
            );
          }
          break;
        }
        case 'allClosed':
          dispatch(
            saveSlide({
              open: false,
            }),
          );
          setLittenChatPreviewId(null);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (
      searchParams.get('show')
      && searchParams.get('chatId')
      && !isInitialized.current
    ) {
      setLittenChatPreviewId(searchParams.get('chatId'));
      isInitialized.current = true;
    }
  }, [searchParams]);

  useEffect(() => {
    window.addEventListener('message', manageIframeMessages);

    postMessageToLitten('getUserData', {});
    return () => {
      window.removeEventListener('message', manageIframeMessages);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { littenChatPreviewId };
};

export default useIFrameMessages;
