import styled from 'styled-components';

import { Colors } from '../../../utils';

const SortButton = styled.button`
  background-color: rgba(0,0,0,0) !important;
  border: none;
  color: #7d60f5;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  &:hover {
    cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  }
`;

const TableContainer = styled.div`
  width: 100%;
  height:  ${(props) => (props.fullHeight ? '83vh' : '70vh')};
  overflow: ${(props) => (props.scrollable ? 'auto' : 'initial')};
`;

const TableWrapper = styled.table`
  position: relative;
  border: 1px solid #DEDEDE;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  background: #fff;
  border-spacing: 0;
  height: 100%;
  tbody {
    height: calc(100% - 34px);
    overflow-y: auto;
    overflow-x: hidden;
    display: contents;
    width: 100%;
    tr {
      width: 100%;
      display: inline-table;
    }
  }
  thead {
    width: 100%;
    display: block;
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 100;
    tr {
      width: 100%;
      display: inline-table;
    }
    td {
      background-color: #F2F2F2;
      padding: 8px 8px 8px 12px;
      text-align: left;
      font-weight: bold;
      font-size: 14px;
      color: #7868C3;
      min-width: 144px;
      i{
        margin-left: 10px;
        cursor: pointer;
        color: #B3B3B3;
      }
    }
  }

  td {
    border-bottom: 1px solid #E6E6E6;
    min-width: 144px;
  }
`;

const TrBody = styled.tr`
  td:first-child{
    border-left: 4px solid ${(props) => (props.selected ? '#7D60F5' : 'transparent')};
    font-weight: ${(props) => (props.selected ? 'bold' : 'none')};
    span {
      ${(props) => (props.mobile ? 'text-decoration: underline' : '')};
      text-decoration: ${(props) => (props.selected ? 'none' : 'underline')};
      &:hover {
        text-decoration: none;
      }
    }
  }
  background-color: ${(props) => (props.selected ? '#F7F7F7' : 'transparent')};
  cursor:pointer;
  &:hover{
    background-color: #F7F7F7;
  }
`;

const Data = styled.td`
  @media (max-width: 420px) {
    display: ${(props) => (props.mobile ? 'table-cell' : 'none !important')};
    ${(props) => props.fromTBody && props.mobile && 'padding: 12px 8px;'}
  }
  opacity: ${(props) => (props.deleted ? '0.5' : '1')};
  border-top-left-radius: ${(props) => (props.borderLeft ? '8px' : '0px')};
  border-top-right-radius: ${(props) => (props.borderRight ? '8px' : '0px')};

  ${(props) => props.fromTBody && 'padding: 8px;'}
`;

const DataEmpty = styled.td`
  @media (max-width: 420px) {
    display: ${(props) => (props.mobile ? 'table-cell' : 'none !important')};
    ${(props) => props.fromTBody && props.mobile && 'padding: 12px 8px;'}
  }
  opacity: ${(props) => (props.deleted ? '0.5' : '1')};
  border-top-left-radius: ${(props) => (props.borderLeft ? '8px' : '0px')};
  border-top-right-radius: ${(props) => (props.borderRight ? '8px' : '0px')};
  border-bottom: none !important;

  ${(props) => props.fromTBody && 'padding: 8px;'}
`;

const NoInfo = styled.td`
  position: absolute;
  border: none!important;
  height: 71%;
  padding: 9% 0 0 0;
  background: #ffffff85;
  width: 100%;
  a {
   font-weight: bold;
   color: ${Colors.blueLinkLight};
   text-decoration: underline;
   cursor: pointer;
  }
  img{
    width: 230px;
    height: 190px;
  }
  h4{
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    margin: 12px 0 0 0;
  }
  p{
    text-align: center;
    margin: 0;
  }
  p.paragraph{
    font-size: 16px;
    margin: 14px 0 5px 0;
  }
  span{
    text-align: center;
    font-size: 12px;
  }
`;

const SkeletonPulse = styled.span`
  display: flex;
  height: 20px;
  margin:5px;
  width: 100%;
  min-width: 30px;
  max-width: calc(100% - ${(props) => props.randomOffset || 0});
  background: linear-gradient(-90deg, #F0F0F0 0%, #F8F8F8 50%, #F0F0F0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -135% 0;
    }
  }
`;

const EmptyState = styled.span`
  display: flex;
  height: 10px;
  margin: 12px 5px;
  width: 100%;
  min-width: 30px;
  max-width:60%;
  background-size: 400% 400%;
  border-radius: 4px;
`;

const Label = styled.span`
  @media (max-width: 420px) {
    display: ${(props) => (props.mobile ? 'block' : 'none')};
  }
  display: ${(props) => (props.mobile ? 'none' : 'block')};
  position: relative;
  float: left;
`;
const TableCell = styled.span`
  position: relative;
  float: left;
`;
export {
  TableContainer,
  TableWrapper,
  TrBody,
  Data,
  DataEmpty,
  NoInfo,
  SkeletonPulse,
  EmptyState,
  Label,
  TableCell,
  SortButton,
};
