import store from '../Store';
import { IData } from '../Store/Authentication/Types';
import { isLaunchDarklyFlagOn } from '../services/launchDarklyService';

export const hasLabEcom = (): boolean => {
  const flagShouldShowTags = isLaunchDarklyFlagOn(
    'feature.ibb.conversations.lit.crm.tagsAutomaticos.ecom-1074',
  );
  const { auth } = store.getState();
  const { account } = auth as unknown as IData;
  const isEcom = !!account.labs.ecommerce;
  if (isEcom || flagShouldShowTags) {
    return true;
  }
  return false;
};

export default hasLabEcom;
