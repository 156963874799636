import { Colors } from './index';

const channelIcons = {
  WEB: 'demographics',
  WHATSAPP: 'wa-solid',
  FACEBOOK: 'messenger',
  INSTAGRAM: 'instagram',
  WSPLITE: 'wa-solid',
};

const statusClient = {
  ONLINE: { text: 'En línea', color: Colors.successColor, key: 'visitors_section.online' },
  AWAY: { text: 'Ausente', color: Colors.gold, key: 'visitors_section.offline' },
};

export {
  channelIcons,
  statusClient,
};
