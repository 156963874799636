import store from '../Store';
import { IData } from '../Store/Authentication/Types';

export type FeaturesByPlan = {
  showTagColumns: boolean;
  showTagFilter: boolean;
  useTagFilter: boolean;
};

const featuresByPlan = {
  free: {
    showTagColumns: false,
    showTagFilter: true,
    useTagFilter: false,
  },
  freeTrial: {
    showTagColumns: true,
    showTagFilter: true,
    useTagFilter: true,
  },
  starter: {
    showTagColumns: false,
    showTagFilter: true,
    useTagFilter: false,
  },
  elite: {
    showTagColumns: true,
    showTagFilter: true,
    useTagFilter: true,
  },
  corporate: {
    showTagColumns: true,
    showTagFilter: true,
    useTagFilter: true,
  },
} as Record<string, FeaturesByPlan>;

const planNameEnum = {
  CLIENGO_FREE: 'free',
  CLIENGO_ECOM_FREE: 'free',
  CLIENGO_ECOM_FREE_TRIAL: 'freeTrial',
  CLIENGO_FREE_TRIAL: 'freeTrial',
  CLIENGO_ECOM_ELITE: 'elite',
  CLIENGO_ELITE: 'elite',
  CLIENGO_ECOM_CORPORATE: 'corporate',
  CLIENGO_ECOM_CORPORATE_ANNUAL: 'corporate',
  CLIENGO_ECOM_PLUS: 'starter',
  CLIENGO_ECOM_PLUS_ANNUAL: 'starter',
  CLIENGO_STARTER: 'starter',
  CLIENGO_STARTER_ANNUAL: 'starter',
  CLIENGO_PREMIUM: 'elite',
  CLIENGO_PREMIUM_ANNUAL: 'elite',
  CLIENGO_CORPORATE: 'corporate',
  CLIENGO_CORPORATE_ANNUAL: 'corporate',
  CLIENGO_CONVERSACIONES_CORPORATE: 'corporate',
  CLIENGO_STARTER_CONVER: 'starter',
  CLIENGO_STARTER_CONVER_ANNUAL: 'starter',
  CLIENGO_PREMIUM_CONVER: 'elite',
  CLIENGO_PREMIUM_CONVER_ANNUAL: 'elite',
  CLIENGO_PREMIUM_WA_CONVER: 'elite',
  CLIENGO_PREMIUM_WA_CONVER_ANNUAL: 'elite',
  CLIENGO_BUSINESS_CONVER: 'elite',
  CLIENGO_BUSINESS_CONVER_ANNUAL: 'elite',
  CLIENGO_CORPORATE_CONVER: 'corporate',
  CLIENGO_CORPORATE_CONVER_ANNUAL: 'corporate',
  CLIENGO_STARTER_CONVER_2024: 'starter',
  CLIENGO_STARTER_CONVER_ANNUAL_2024: 'starter',
  CLIENGO_PREMIUM_CONVER_2024: 'elite',
  CLIENGO_PREMIUM_CONVER_ANNUAL_2024: 'elite',
  CLIENGO_PREMIUM_WA_CONVER_2024: 'elite',
  CLIENGO_PREMIUM_WA_CONVER_ANNUAL_2024: 'elite',
  CLIENGO_BUSINESS_CONVER_2024: 'elite',
  CLIENGO_BUSINESS_CONVER_ANNUAL_2024: 'elite',
  CLIENGO_CORPORATE_CONVER_2024: 'corporate',
  CLIENGO_CORPORATE_CONVER_ANNUAL_2024: 'corporate',
} as Record<string, string>;

export function featureAccessAdapter(planId: string) {
  const features = featuresByPlan[planNameEnum[planId]];
  if (features) {
    return features;
  }
  return featuresByPlan.starter;
}

export function hasFeatureAccess(feature: keyof FeaturesByPlan): boolean {
  const { features } = store.getState().auth as unknown as IData;
  if (features) {
    return features[feature];
  }
  return false;
}
