import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import TableLit from '../../Components/General/Table/TableLit.component';
import { hasLabEcom } from '../../labs/hasLabEcom';
import { StateAllChats } from '../../Interfaces/AllChats';
import { hasFeatureAccess } from '../../utils/featureAccess';

import useSortingItems from './sortingTable';
import { TableAllChatsProps } from './AllChatsTypes';

const TableAllChats = ({
  slidePanel,
  itemsTableLit,
  loading,
  noData,
  checkEmpty,
  showAsigned,
  littenChatPreviewId,
}: TableAllChatsProps) => {
  const [items, setItems] = useState(itemsTableLit);

  useEffect(() => {
    setItems([...itemsTableLit]);
  }, [itemsTableLit]);

  const {
    sortByChannel,
    sortByStatus,
    sortByLastContactDate,
    sortByVisitorName,
    sortByOperatorName,
    nameHeader,
    channelHeader,
    statusHeader,
    lastContactHeader,
    chattingWithHeader,
    actionsHeader,
    tags,
  } = useSortingItems(items, setItems);

  const headerTableLit = [
    lastContactHeader,
    ...(hasLabEcom() && hasFeatureAccess('showTagColumns') ? [tags] : []),
    statusHeader,
    nameHeader,
    channelHeader,
    ...(showAsigned ? [chattingWithHeader] : []),
    actionsHeader,
  ];

  const sortChatsBy = (header: { key: string; value: string }): void => {
    /** map of sorting functions */
    const sortingFunctions: { [key: string]: (sort: string) => void } = {
      /** sort by `status`. Wheter is `ONLINE` or `AWAY` */
      status: sortByStatus,
      /** sort by `lastContact` date */
      lastContact: sortByLastContactDate,
      /** sort by name (aka visitor name) */
      name: sortByVisitorName,
      /** sort by `chattingWith` (aka operator name) */
      chattingWith: sortByOperatorName,
      /** sort by `channel` */
      channel: sortByChannel,
    };
    return sortingFunctions[header.key](header.value);
  };

  return (
    <TableLit
      header={headerTableLit}
      items={items}
      sortCallback={(header: { key: string; value: string }) => sortChatsBy(header)}
      loading={loading}
      rowSelected={littenChatPreviewId}
      noData={noData}
      checkEmpty={checkEmpty}
      isOpenSlide={slidePanel!.open}
      isLittenPanelOpen={slidePanel!.isLittenPanelOpen}
    />
  );
};

const mapStateToProps = (state: StateAllChats) => ({
  slidePanel: state.slidePanel,
});

export default connect(mapStateToProps)(TableAllChats);
