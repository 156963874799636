import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import Cookies from 'js-cookie';

import { AppState } from '../index';
import config from '../../services/config';
import setAuthToken from '../../services/setAuthToken';

import { ISite, IWebsite, IUser } from './Types';
import { logout, setUsers, setWebsites } from './Action';

/**
 * Fetch websites and users
 * @param flagShouldShowTags
 */
export const fetchWebsitesAndUsers = ():
ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  try {
    const websites: {data:{results: ISite[], hasAnyScriptInstalledresults: {scriptInstalled: boolean}[]}} = await axios.get(`${config.urls.API_URL}/sites`);
    const allUsers: {data: {results: IUser}} = await axios.get(
      `${config.urls.API_URL}/users?includeDeleted=true`,
      { withCredentials: true },
    );
    const dataSites: IWebsite = {
      sites: websites.data.results,
      hasAnyScriptInstalled: websites.data.results.some(
        (web) => web.scriptInstalled,
      ),
    };
    dispatch(setWebsites(dataSites));
    dispatch(setUsers(allUsers.data.results));
  } catch (error) {
    console.info(error);
  }
};

export const logoutUser = (history: {push: (val: string) => void}):
ThunkAction<void, AppState, null, Action<string>> => (dispatch) => {
  Cookies.remove('jwt');
  setAuthToken(false);
  dispatch(logout());
  history.push('/login');
};
