export const Images = {
  emptyDataTable:
    'https://res.cloudinary.com/hbrrdozyj/image/upload/v1616081892/cliengo/folder_empty_state.svg',
  emptyDataTableFilter:
    'https://res.cloudinary.com/hbrrdozyj/image/upload/v1616081892/cliengo/folder_empty_state.svg',
  messagesErrorInConversation:
    'https://res.cloudinary.com/hbrrdozyj/image/upload/v1637941257/Bot_eimqv9.svg',
};

export const CHANNELS = [
  {
    id: 'facebook',
    name: 'Facebook',
    icon: 'messenger',
  },
  {
    id: 'web',
    name: 'Web',
    icon: 'demographics',
  },
  {
    id: 'whatsapp',
    name: 'Whatsapp',
    icon: 'wa-solid',
  },
  {
    id: 'wsplite',
    name: 'WhatsApp Lite',
    icon: 'wa-solid',
  },
];
export const CONDITIONS = [
  {
    id: 'lead',
    name: 'Lead',
  },
  {
    id: 'intervened',
    name: 'Intervened',
  },
];

export const FORMATS_DATE_TIME = {
  en: 'MM/DD/YYYY, h:mm a',
  es: 'DD/MM/YYYY, h:mm a',
  pt: 'DD/MM/YYYY, h:mm a',
};

export const LOCAL_TAGS_KEY = 'cliengo_local_tags';
