import React, { useEffect, useRef, useState } from 'react';
import { translate } from "../../services/translate";

const ICONS = {
  FACEBOOK: {
    icon: 'facebook-color',
    color: '#1778F2',
  },
  INSTAGRAM: {
    icon: 'instagram',
    color: '#FC5241',
  },
  WHATSAPP: {
    icon: 'whatsapp',
    color: '#4DC06A',
  },
  WEBSITE: {
    icon: 'demographics',
    color: '#7D60F5',
  },
  WSPLITE: {
    icon: 'whatsapp',
    color: '#4DC06A',
  },
};

export const WebsiteFilter = ({ websites, onChange, selectedOption = "" }) => {
  const [hidden, setHidden] = useState(false);
  const [selected, setSelected] = useState(selectedOption ? websites.find(w => w.value === selectedOption) : undefined);
  const ref = useRef();
  const [searchText, setSearchText] = useState('');
  const [filteredWebsites, setFilteredWebsites] = useState([]);

  function removeAccents(text) {
    return text.normalize('NFD').replace(/\p{Diacritic}/gu, '');
  }

  useEffect(() => {
    // Apply the filters to the users lists.
    if (websites) {
      if (searchText) {
        const websitesList = websites.filter((website) => removeAccents(website.label.toLowerCase()).includes(
          removeAccents(searchText).toLowerCase(),
        ));
        setFilteredWebsites(websitesList);
      } else {
        setFilteredWebsites(websites);
      }
    }
  }, [websites, searchText, onChange]);

  useEffect(() => {
    // Sirve para ocultar el select cuando se clickea fuera del mismo
    const checkIfClickedOutside = (e) => {
      if (hidden && ref.current && !ref.current.contains(e.target)) {
        setHidden(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [hidden]);
  const selectWebsite = (website) => {
    if (website.value !== selected?.value) {
      setSelected(website);
      onChange(website.value);
      setSearchText("");
    } else {
      setHidden(false);
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <section className="select-chatbot">
      <div className="select-chatbot__form">
        <div
          id="chatbot_select"
          className={`input-select-chatbot ${hidden ? 'open' : ''}`}
          onClick={() => setHidden(!hidden)}
          ref={ref}
          data-test="chatbot_select"
        >
          <div className="input-select-chatbot__selected">
            {selected?.value && !hidden && (
              <>
                {selected?.value !== 'all' && (
                  <i
                    className={`icon icon-${ICONS[selected?.type]?.icon ?? ICONS.WEBSITE.icon}`}
                    style={{ color: ICONS[selected?.type]?.color ?? ICONS.WEBSITE.color}}
                  />
                  )}
                <label>{selected?.label}</label>
              </>
            )}
            {!selected && !hidden && <label>{translate('history_section.allSite')}</label>}
            {hidden &&
              <input
                placeholder={translate('website_filter.search')}
                className="filter-website"
                type="text"
                value={searchText}
                onChange={handleSearchChange}
                onClick={handleEdit}
              />
            }
            <i className="icon icon-expand-more" />
          </div>
          <ul className={hidden ? '' : 'hidden'}>
            {filteredWebsites.map((website) => (
              <li
                onClick={() => selectWebsite(website)}
                key={website.value}
              >
                {website.value !== 'all' && (
                  <i
                    className={`icon icon-${ICONS[website.type]?.icon ?? ICONS.WEBSITE.icon}`}
                    style={{ color: ICONS[website.type]?.color ?? ICONS.WEBSITE.color}}
                  />
                )}
                <label>{website.label}</label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
