import React from 'react';

import { translate } from '../../services/translate';
import config from '../../services/config';
import analytics from '../../services/analytics';

const EmptyTable = (props) => {
  const handleEvent = () => {
    analytics.trackEvent({
      name: 'live_bot_config_click',
      action: 'Configurar Bot en Live',
      category: 'live_emptystates',
      label: '',
    });
  };

  return (
    <div className="container-empty">
      <p>
        <img alt="img" src={props.data.img} />
      </p>
      <p className="title width-small">{props.data.title}</p>
      <p className="content width-small">{props.data.subtit}</p>
      {props.data.tip && (
        <p>
          <span>{props.data.tip}</span>
        </p>
      )}
      {props.data.checklist && (
        <p className="legend content width-small">
          {props.data.checklist.label}
        </p>
      )}
      {props.data.showConfigureBot && (
        <a
          type="button"
          className="buttonPrimary"
          href={`${config.urls.CRM}/createbot`}
          target="_blank"
          rel="noreferrer noopener"
          onClick={handleEvent}
        >
          {translate('configure_bot')}
        </a>
      )}
      {props.data.checklist &&
        props.data.checklist.points?.length > 0 &&
        props.data.checklist.points.map((point, index) => (
          <div className="row width-small" key={index}>
            <i className="icon-check2" />
            <span style={{ fontSize: '16px' }} />
            <p className="legend">{point}</p>
          </div>
        ))}
    </div>
  );
};

export default EmptyTable;
