import styled from 'styled-components';

export const TagContainer = styled.span`
  padding: 5px 10px;
  color: ${ props => props.color };
  border: solid 1px ${ props => props.color };
  border-radius: 4px;
  text-transform: uppercase;
  max-width: 122px;
  height: 13px;
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
