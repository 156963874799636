import LaunchDarklyClientSdk, { LDClient } from 'launchdarkly-js-client-sdk';

import { IAccount, IUser } from '../Store/Authentication/Types';

import config from './config';

let launchDarklyClient: LDClient;
type updateFlagsType = {
  [key: string]: { previous: boolean; current: boolean };
};
let updatedFlags: updateFlagsType = {};

export const initializeLDClient = (userData: IUser, accountData: IAccount) => {
  launchDarklyClient = LaunchDarklyClientSdk.initialize(
    config.launchdarkly.key,
    {
      kind: 'user',
      key: userData.id,
      name: userData.name,
      email: userData.email,
      companyId: accountData.id,
      companyCreationDate: Math.floor(
        new Date(accountData.creationDate).getTime() / 1000,
      ),
      creationDate: new Date(accountData.creationDate).getTime(),
    },
  );

  launchDarklyClient.on('change', (newData: updateFlagsType) => {
    updatedFlags = { ...updatedFlags, ...newData };
  });
};

export const isLaunchDarklyFlagOn = (flagName: string): boolean => updatedFlags[flagName]?.current
  || (launchDarklyClient.variation(flagName, false) as boolean);
