export const removePreLoader = () => {
  const preloader = document.querySelector('#clgo-preloader');
  if (preloader) {
    const path = document.querySelector('#clgo-preloader path');
    if (path) path.style.display = 'none';
    const svg = document.querySelector('#clgo-preloader svg');
    if (svg) {
      svg.style.transform = 'scale(15)';
      svg.addEventListener('transitionend', () => {
        preloader.style.opacity = '0';
      });
    }
  }
};
