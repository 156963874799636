import React from 'react';

import IFrameApp from './IFrameApp';

const AppContainer = () => (
  <div style={{ background: '#f7f7f7', height: '100vh', overflow: 'hidden' }}>
    <IFrameApp />
  </div>
);

export default AppContainer;
