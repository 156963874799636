/* eslint-disable import/prefer-default-export */
import { SET_CURRENT_USER } from '../actions/types';

import { LOGOUT, SET_HAS_CONVERSATIONS, State } from './Types';

const initialStores = {
  isAuthenticated: false,
  account: {},
  user: {},
  companyHasConversations: null,
};

export const AuthReducer = (
  state = initialStores,
  action: { payload: State; type: string },
) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };
    case SET_HAS_CONVERSATIONS:
      return {
        ...state,
        companyHasConversations: action.payload,
      };
    case LOGOUT:
      return initialStores;
    default:
      return state;
  }
};
