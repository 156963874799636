import React from 'react';

import { Colors } from '../../../../utils';
import Tooltip from '../../../Tooltip/Tooltip';

import { TagContainer } from './TagButton.styles';
import { ITagButton } from './TagButton.types.d';

export const TagButton = ({ text, color, handler }: ITagButton) => (
  <Tooltip
    delay={400}
    direction="bottom"
    alignmentX="-45%"
    alignmentY="-90%"
    tooltipColor={Colors.colorFontGeneral}
    tooltipHeight="auto"
    tooltipWidth="122px"
    content={text}
  >
    <TagContainer
      color={Colors[color] || '#3B86FF'}
      onClick={handler}
    >
      {text}
    </TagContainer>
  </Tooltip>
);

export default TagButton;
