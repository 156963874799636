import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { StateAllChats, Convs } from '../../Interfaces/AllChats';
import TableLit from '../../Components/General/Table/TableLit.component';
import useSortingItems from '../AllChats/sortingTable';

interface Props {
  itemsTable: Convs[];
  loading: boolean;
  noData: {
    img: string;
    title: string;
    subtit: string;
    checklist: { label: string; points: string } | null;
  };
  checkEmpty: boolean;
}
const TableHistory = ({
  itemsTable, loading, noData, checkEmpty,
}: Props) => {
  const slidePanel = useSelector((state: StateAllChats) => state.slidePanel);
  const isOpenSlide = useSelector(
    (state: StateAllChats) => state.slidePanel.open,
  );
  const isLittenPanelOpen = useSelector(
    (state: StateAllChats) => state.slidePanel.isLittenPanelOpen,
  );

  const [items, setItems] = useState<Convs[]>([...itemsTable]);
  useEffect(() => {
    setItems([...itemsTable]);
  }, [itemsTable]);

  const {
    sortByChannel,
    sortByStatus,
    sortByLastContactDate,
    sortByVisitorName,
    sortByOperatorName,
    sortByContition,
    nameHeader,
    channelHeader,
    lastContactHeader,
    intervenedBy,
    actionsHeader,
    conditionHeader,
  } = useSortingItems(items, setItems);

  const headerTableHistory = [
    nameHeader,
    lastContactHeader,
    conditionHeader,
    intervenedBy,
    channelHeader,
    actionsHeader,
  ];

  const sortChatsBy = (header: {
    key:
      | 'status'
      | 'lastContact'
      | 'name'
      | 'chattingWith'
      | 'channel'
      | 'condition'
      | 'intervened';
    value: string;
  }) => {
    const sortingFunctions = {
      status: sortByStatus,
      lastContact: sortByLastContactDate,
      name: sortByVisitorName,
      chattingWith: sortByOperatorName,
      channel: sortByChannel,
      condition: sortByContition,
      intervened: sortByOperatorName,
    };
    return sortingFunctions[header.key](header.value);
  };

  return (
    <TableLit
      header={headerTableHistory}
      items={items}
      sortCallback={(header: {
        key:
          | 'status'
          | 'lastContact'
          | 'name'
          | 'chattingWith'
          | 'channel'
          | 'condition'
          | 'intervened';
        value: string;
      }) => sortChatsBy(header)}
      loading={loading}
      rowSelected={slidePanel?.content?.selectedIndex}
      noData={noData}
      className="tableHistory"
      checkEmpty={checkEmpty}
      isOpenSlide={isOpenSlide}
      isLittenPanelOpen={isLittenPanelOpen}
    />
  );
};

export default TableHistory;
