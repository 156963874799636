import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Tabs } from 'cliengo-ui';
import styled from 'styled-components';

import { RootState } from '../../Interfaces/Default';
import {
  MainContainerTable,
  MasterDetailContainer,
} from '../../GlobalStyles/Layouts';
import TagSelectorComponent from '../../Components/TagSelector/TagSelector.component';
import analytics, { EVENTS } from '../../services/analytics';
import {
  executeOnlyOneTime,
  getLocalStorage,
  setLocalStorage,
  deleteLocalStorage,
} from '../../utils';
import { translate } from '../../services/translate';
import { hasLabEcom } from '../../labs/hasLabEcom';
import { Convs } from '../../Interfaces/AllChats';
import { WebsiteFilter } from '../../Components/WebsiteFilter/WebsiteFilter.component';

import {
  StyledSearchSelect,
  ContentTitleTab,
  SearchWrapper,
  SearchWrapperInput,
  TextInputLive,
} from './allChats.style';
import TableAllChats from './Table.component';
import {
  AllChatsComponentProps,
  GenericTablePropsType,
  SitesOptionsType,
  TabType,
  BasicType,
} from './AllChatsTypes';
import filterByTags from './filterByTag';

const useQuery = () => new URLSearchParams(useLocation().search);

const AllChatsComponent = ({
  chats,
  loading,
  hasScriptInstalled,
  sitesOptions,
  getTableData,
  OnTabPessCallback,
  preselectedTab,
  littenChatPreviewId,
  companyHasConversations,
  ...rowProps
}: AllChatsComponentProps) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const privileges = ['admin', 'websites_write'];
  const hasChannelsAccess = user?.permissions.some((priv) => privileges.includes(priv));

  const TitleTab = ({ name, number }: BasicType) => (
    <ContentTitleTab>
      {`${name} `}
      <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
        {`(${number})`}
      </span>
    </ContentTitleTab>
  );

  const trackSearchEventOnce = executeOnlyOneTime((query: string) => {
    analytics.trackEvent({
      name: EVENTS.CHAT_SEARCH,
      label: query,
    });
  });

  const GenericTable = ({
    items,
    noData,
    showAsigned,
  }: GenericTablePropsType) => {
    const textInput = useRef<HTMLInputElement>(null);
    const [filtered, setFiltered] = useState(items);
    const history = useHistory();
    const query = useQuery();
    const querySearch = getLocalStorage('search') as string; // por si trae querys
    const queryWeb = useQuery().get('web'); // para ver si abre detalles o no
    const [selectedOpt, setselectedOpt] = useState(queryWeb ?? 'all');
    const [chatFilter, setchatFilter] = useState(querySearch ?? '');

    const addCounterToWebsitesList = () => {
      const chatsByWebsites: { all: number; [key: string]: number } = {
        all: items.length,
      };
      items.forEach(
        (item) => (chatsByWebsites[item.webId] = (chatsByWebsites[item.webId] || 0) + 1),
      );
      return sitesOptions.map(({ value, label, type }: SitesOptionsType) => ({
        value,
        label: `${label} (${chatsByWebsites[value] ?? 0})`,
        website: value === 'all' ? value : label,
        type,
      }));
    };
    const [checkEmpty, setCheckEmpty] = useState(false);
    const [websites, setWebsites] = useState(addCounterToWebsitesList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setWebsites(addCounterToWebsitesList()), []);

    const trackSelectedWebsite = (selectedWebsite: string) => {
      analytics.trackEvent({
        name: EVENTS.SITE_FILTER,
        label: selectedWebsite,
      });
    };

    const filterData = useCallback(
      (selected = 'all', query = '') => {
        const queryLowerCase = String(query).toLowerCase();

        const filteredBySite = items.filter(
          (chat) => selected === 'all' || selected === chat?.webId,
        );
        const filteredData = filteredBySite.filter((itm) => {
          const visitorName: string = itm.table[0].label.props.children;
          const operatorName: string = itm.table[4].label.props.children;

          return (
            visitorName?.toLowerCase().includes(queryLowerCase)
            || operatorName?.toLowerCase().includes(queryLowerCase)
          );
        });
        return filteredData;
      },
      [items],
    );

    const byWeb = (selected: string) => {
      query.delete('tag');
      setselectedOpt(selected);

      if (selected !== 'all') {
        query.set('web', selected);
      } else {
        query.delete('web');
      }
      history.replace({ search: query.toString() });
    };

    useEffect(() => {
      const filteredData = filterData(selectedOpt, chatFilter);
      setFiltered(filteredData);

      const { website } = websites.find((ws) => ws.value === selectedOpt) ?? {
        website: 'all',
      };
      trackSelectedWebsite(website);
      if (filteredData.length === 0) {
        setCheckEmpty(true);
      }
    }, [selectedOpt]);

    const byInputChange = (selected: string) => {
      setchatFilter(selected);

      if (selected !== '') {
        setLocalStorage('search', selected);
      } else {
        deleteLocalStorage('search');
      }
    };

    useEffect(() => {
      setCheckEmpty(false);
      const filteredItems = filterData(selectedOpt, chatFilter);
      if (chatFilter.length > 0) {
        trackSearchEventOnce(chatFilter);
        if (filteredItems.length === 0) {
          setCheckEmpty(true);
        }
      }
      setFiltered([...filteredItems]);
    }, [chatFilter, textInput.current]);

    return (
      <div
        className="is-flex is-flex-direction-column is-full-height"
        style={{ height: 'calc(100vh - 90px)' }}
      >
        <StyledSearchSelect>
          <SearchWrapper
            style={{
              display: hasLabEcom() ? 'grid' : 'flex',
              gridTemplateColumns: '1fr 1fr',
              gap: '1rem',
            }}
          >
            <WebsiteFilter
              websites={websites}
              onChange={(selected: string) => byWeb(selected)}
              selectedOption={selectedOpt}
            />

            <TagSelectorComponent />
          </SearchWrapper>
          <SearchWrapperInput>
            <i className="icon-search1" />
            <TextInputLive
              id="allChatsSearchInput"
              callback={(e: string) => byInputChange(e)}
              className="conversation-search-input"
              value={chatFilter}
              placeholder={translate('visitors_section.search_chat')}
              ref={textInput}
            />
          </SearchWrapperInput>
        </StyledSearchSelect>

        {/* LA TABLA DE LIT */}
        <TableAllChats
          {...rowProps}
          itemsTableLit={
            filterByTags(filtered, query.get('tags') ?? '') as Convs[]
          }
          loading={loading}
          noData={noData}
          checkEmpty={checkEmpty}
          showAsigned={showAsigned}
          littenChatPreviewId={littenChatPreviewId}
          // preselectedConver={preselectedConver}
        />
      </div>
    );
  };

  // EL CONTENIDO DE CADA TAB
  const contentTab = [
    {
      id: 'available',
      title: (
        <TitleTab
          name={translate('visitors_section.without_operators')}
          number={chats.available.length || 0}
        />
      ),
      content: () => (
        <GenericTable
          items={getTableData(chats.available)}
          noData={getEmptyStateInfo(hasChannelsAccess).available}
          showAsigned={false}
        />
      ),
    },
    {
      id: 'taken',
      title: (
        <TitleTab
          name={translate('visitors_section.chatting')}
          number={chats.taken.length || 0}
        />
      ),
      content: () => (
        <GenericTable
          items={getTableData(chats.taken)}
          noData={getEmptyStateInfo(hasChannelsAccess).taken}
          showAsigned
        />
      ),
    },
    {
      id: 'all',
      title: (
        <TitleTab
          name={translate('visitors_section.all')}
          number={chats.taken.length + chats.available.length || 0}
        />
      ),
      content: () => (
        <GenericTable
          items={getTableData(chats.available.concat(chats.taken))}
          noData={getEmptyStateInfo(hasChannelsAccess).all}
          showAsigned
        />
      ),
    },
  ];

  // OBTIENE LA INFO DEL EMPTY STATE SI TIENE O NO SCRIPT INSTALADO
  const getEmptyStateInfo = (hasChannelsAccess: boolean) => {
    const showConfigureBot = hasChannelsAccess
      && !hasScriptInstalled
      && companyHasConversations === false;

    return {
      available: {
        img: 'https://res.cloudinary.com/hbrrdozyj/image/upload/v1616081892/cliengo/visitors_empty_state.svg',
        title: translate('empty_state_section.welcome'),
        subtit: showConfigureBot
          ? translate('visitors_section.no_chats_found_without_bot')
          : translate('visitors_section.no_chats_found_with_bot'),
        showConfigureBot,
        tip: '',
      },
      taken: {
        img: 'https://res.cloudinary.com/hbrrdozyj/image/upload/v1616081892/cliengo/folder_empty_state.svg',
        title: translate('empty_state_section.no_visitors_chatting_now'),
        subtit: translate('empty_state_section.talk_to_visitor'),
        tip: '',
      },
      all: {
        img: 'https://res.cloudinary.com/hbrrdozyj/image/upload/v1616081892/cliengo/folder_empty_state.svg',
        title: translate('empty_state_section.no_visitors_chatting_now'),
        subtit: translate('empty_state_section.talk_to_visitor'),
        tip: '',
      },
    };
  };

  /**
   * `trackActiveTabEvent` sends the live_tab event.
   * @param {'available'|'taken'|'all'} tab - the pressed tab.
   */
  const trackActiveTabEvent = (tab: string) => {
    analytics.trackEvent({
      name: EVENTS.ACTIVE_TAB,
      label: tab,
    });
  };

  const OnPressed = useCallback(
    (tab: TabType) => {
      trackActiveTabEvent(tab.id);
      OnTabPessCallback(tab.id);
    },
    [OnTabPessCallback],
  );

  const onPressWhiteBox = () => {
    OnTabPessCallback('white');
  };

  function trackEventsOnFirstComponentRender() {
    analytics.trackPage(EVENTS.VISITORS);
    trackActiveTabEvent(contentTab[0].id);
  }
  useEffect(trackEventsOnFirstComponentRender, []);

  return (
    <MasterDetailContainer>
      {/* CONTENIDO PRINCIPAL */}
      <ChatsContainer>
        <WhiteBox onClick={() => onPressWhiteBox} />
        <StyledMainContainer littenChatPreviewId={littenChatPreviewId}>
          <Tabs
            contentTab={contentTab}
            classTabHeader="StyledHeader"
            classActiveTab="activeTab"
            classTabContent="StyledContentTab"
            pressTabCallback={OnPressed}
            preActivated={preselectedTab}
          />
        </StyledMainContainer>
      </ChatsContainer>
    </MasterDetailContainer>
  );
};

const WhiteBox = styled.div`
  height: 100%;
  width: 90%;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  @media screen and (min-width: 1080px) {
    display: block;
  }
`;

const ChatsContainer = styled.div`
  margin-left: 5%;
  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
`;

const StyledMainContainer = styled(MainContainerTable)`
  padding-top: 30px;
  .StyledHeader {
    width: 414px;
  }
  .activeTab {
    font-weight: bold;
  }
  .StyledContentTab {
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    height: calc(100% - 84px);
    overflow: auto;
  }
`;

export default AllChatsComponent;
