import styled from 'styled-components';

import { Colors } from '../../utils';

export const Tag = styled.div`
  border: solid 1px;
  padding: 5px 5px 3px 5px;
  border-radius: 4px;
  font-size: 10px;
  color: ${({ Color }: {Color: string}) => Color};
  border-color: ${({ Color }: {Color: string}) => Color};
`;

export const AlignedDate = styled.div`
  margin-left: 35px;
`;

export const BtnExport = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 18px;
  border: 1px solid ${Colors.greenColor};
  background: ${Colors.greenColor};
  color: white;
  cursor: pointer;
  border-radius: 999px;
  &:hover {
    background: ${Colors.greenColorDarker};
  }
  &:active {
    background: ${Colors.greenColorDarker};
    color: white;
  }
`;

export const ExportContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;
