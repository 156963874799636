import { IAccount, IData, IUser } from '../Store/Authentication/Types';
import { featureAccessAdapter } from '../utils/featureAccess';

export interface InitializeAccountProps {
  account?: IAccount;
  plan?: JsonObject;
  user?: IUser;
  companyHasConversations?: boolean | null;
}

export const accountDataAdapter = ({
  account,
  plan,
  user,
  companyHasConversations,
}: InitializeAccountProps) => {
  if (account && plan && user) {
    const planId = plan.id;
    const data: IData = {
      account,
      user,
      features: featureAccessAdapter(planId as string),
      companyHasConversations,
    };

    return data;
  }
};
