import moment from 'moment';

import 'moment/locale/es';
import 'moment/locale/pt';
import es from '../i18n/es';
import en from '../i18n/en';
import pt from '../i18n/pt';
import { LanguageData } from '../Interfaces/Languages';

const files:{es: any; en: any; pt:any;} = { es, en, pt };

let laguange: 'es' | 'en' | 'pt' = 'es';

export const initTranslation = (lang = 'es'): void => {
  const userLang: 'es' | 'en' | 'pt' | string = lang.split('_')[0];
  laguange = userLang === 'es' || userLang === 'en' || userLang === 'pt' ? userLang : laguange;
  moment.locale(userLang);
};

export const translate = (path: string | LanguageData[], separator = '.') : string => {
  if (!path) return '';

  const properties:string[] = Array.isArray(path) ? path
    : path.split(separator);
  let value: any;
  for (let index = 0; index <= properties.length - 1; index++) {
    if (!value) {
      /**
       * Se hardcodea el idioma español.
       * Cuando ya no se necesite, cambiar por:
       * value = files[laguange][properties[index]];
       */
      value = files['es'][properties[index]];
    } else {
      value = value[properties[index]];
    }
  }
  return value;
};
